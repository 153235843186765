const Result = ({ destination, result }) => {
  return (
    <div className='result-container'>
      <div className='result-label-container'>
        <label className='result-container-destination'>{destination}</label>
      </div>
      <div className='result-label-container'>
        <label className='result-label'>{result}</label>
      </div>
    </div>
  )
}

export default Result