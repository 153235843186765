const AutocompleteList = ({ list, onRowSelect }) => {
  return (
    <table>
      <tbody>
        {list.map((item, index) => (
          <tr 
          key={index} 
          onClick={() => onRowSelect(list[index])
          }
          >
            <td style={{borderRadius: ((list.length -1) == index) ? '0em 0em 0.5em 0.5em' : '0em'}} className='autocomplete-cell'>{item}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AutocompleteList;
