import logo from '../assets/logo.png'

const Header = () => {
  return (
    <div className='header'>
        <img className='logo' src={logo}></img>
        <label className="title-main"> WishTrip </label>
        <label className='subtitle'>Your dream trip itinerary built by ChatGPT</label>
    </div>
  )
}

export default Header