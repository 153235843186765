const Filter = ({ onFilterClicked, data }) => {

  const onClick = () => {
    const updated = { ...data, isSelected: !data.isSelected }
    onFilterClicked(updated)
  }

  return (
    <div 
      className='filter-container' 
      style={{ 
        backgroundColor: data.isSelected ? data.color : 'white',
        borderColor: !data.isSelected ? data.color : 'white'
      }}
      onClick={onClick}
    >
      <label 
        className='filter-label'
        style={{ 
          color: !data.isSelected ? data.color : 'white',
        }}
      >
      {data.option}
      </label>
    </div>
  )
}

export default Filter