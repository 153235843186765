const ItineraryButton = ({isEnabled, onTap}) => {
  return (
    <button 
    style={{ 
      backgroundColor: isEnabled ? '#2ecc71' : '#bdc3c7',
      cursor: isEnabled ? 'pointer' : 'cursor',
    }} 
    className='primary-app-button' 
    onClick={onTap}
    >Build an itinerary</button>
  )
}

export default ItineraryButton