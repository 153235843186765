import { continents, countries } from "countries-list"
import { useState } from "react"
import AutocompleteList from "./AutocompleteList";

const DestinationSelector = ({ onStartType, onDestinationSelect }) => {
  const [destination, setDestination] = useState('')
  const [showAutocompleteList, setShowAutocompleteList] = useState(false)
  const [autocompleteList, setAutocompleteList] = useState({})

  const countriesArr = Object.values(countries)
  const continentsArr = Object.values(continents)

  const handleKeyPressed = (e) => {

    if (destination.length > 0) {
      onStartType(true)
    } else {
      onStartType(false)
    }

    const filteredCountries = countriesArr.filter((o) => o.name.toLowerCase().includes(destination.toLowerCase()))
    const filteredContinents = continentsArr.filter((o) => o.toLowerCase().includes(destination.toLowerCase()))
      
    const data = {
      'continents': filteredContinents,
      'countries': filteredCountries
    }
  
    const showList = (destination !== '') && (data['continents'].length !== 0 || data['countries'].length !== 0) 
      
    setShowAutocompleteList(showList)
    setAutocompleteList(createAutocompleteList(data))
  }

  const createAutocompleteList = (data) => {
    let list = []
    const max = 3

    data['continents'].some(c => {
      if (list.length === 3) { return true } 
      list.push(`🌎 ${c}`)
    })

    data['countries'].some(c => {
      if (list.length === 3) { return true } 
      list.push(`${c.emoji} ${c.name}`)
    })

    return list
  }

  const didSelect = (destination) => {
    setDestination(destination)
    setAutocompleteList({})
    setShowAutocompleteList(false)

    onDestinationSelect(destination)
  }

  return (
    <div className='destination-selector-container'>
      <label>Find me somewhere in...</label>
      <div className="autocomplete-container">
        <input 
          type='text' 
          placeholder='Europe'
          value={destination}
          onChange={(e) => setDestination(e.target.value)}
          onKeyUp={handleKeyPressed}
          style={{
            borderRadius: !showAutocompleteList ? '0.5em' : '0.5em 0.5em 0em 0em'
          }}
        />
        { showAutocompleteList ? <AutocompleteList list={autocompleteList} onRowSelect={didSelect} /> : <></> }
      </div>
    </div>
  )
}

export default DestinationSelector