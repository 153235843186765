import DestinationSelector from "./components/DestinationSelector";
import Header from "./components/Header";
import { useEffect, useState } from "react";
import OpenAI from "openai-api";
import Loading from "./components/Loading";
import Result from "./components/Result";
import FilterList from "./components/FilterList";
import stripEmoji from "./helpers/EmojiStrip";
import { continents, countries } from "countries-list"
import ItineraryButton from "./components/Buttons/ItineraryButton";
import SuggestionButton from "./components/Buttons/SuggestionButton";
import RandomButton from "./components/Buttons/RandomButton";
import Prompts from "./data/Prompts";

function App() {
  const [itinerary, setItinerary] = useState('')
  const [showItinerary, setShowItinerary] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [destination, setDestination] = useState('')
  const [selectedFilters, setSelectedFilters] = useState([])
  const [buttonEnabled, setButtonEnabled] = useState(false)

  const openai = new OpenAI(process.env.REACT_APP_KEY)

  const buildPrompt = (prompt, destination) => {
    let text = prompt + ` ${destination}`
    let filters = selectedFilters
    let tripLengthFilter = filters.filter(obj => obj.category === 2)
    let shouldUseWeekLongIncrements = false

    if (prompt == Prompts.Itinerary && tripLengthFilter.length > 0) {
      if (tripLengthFilter[0].option.includes('1 month')) {
        shouldUseWeekLongIncrements = true
      }

      text += ` for ${tripLengthFilter[0].option}`

      // Remove length filter from selected
      filters = filters.filter(obj => obj.category !== 2)
    } else {
      text += ' for 1 week'
    }

    if (shouldUseWeekLongIncrements) {
      text += ' with each destination being a week long increment.'
    }

    if (filters.length > 0) {
      text += ' Use the following criteria for the itinerary: '
    } 
    
    filters.forEach((filter, index) => {
      if (index == (filters.length - 1)) {
        text += `${filter.option}.`
      } else {
        text += `${filter.option}, `
      }
    })

    
    return stripEmoji(text)
  }

  const buildItinerary = async (prompt) => {
    try {
      const response = await openai.complete({
        engine: "text-davinci-003",
        prompt: prompt,
        max_tokens: 600,
        temperature: 0.9,
        topP: 1.0,
        frequencyPenalty: 0.0,
        presencePenalty: 0.0,
      })
      
      // Removes line breaks from start of string
      return response.data.choices[0].text.replace(/^\s+|\s+$/g, '')
    } catch (err) {
      console.log(err)
      setShowLoading(false)
    }
  }

  const getDestination = (d) => { 
    setDestination(d)  
    setButtonEnabled(true)
  }

  const onStartType = (isTextPresent) => {
    if (isTextPresent) {
      setShowItinerary(false)
    } else {
      setButtonEnabled(false)
    }
  }

  const reset = () => {
    setShowLoading(false)
    setSelectedFilters([])
    setButtonEnabled(false)
  }

  const startLoading = () => {
    setShowLoading(true)
  }

  const runMockScenario = () => {
    setTimeout(() => {
      setShowLoading(false)
      setItinerary('A trip to Lapland, Finland can be a magical experience. With its stunning vistas and the opportunity to observe reindeer, it is one of the most unique places to visit. There are also plenty of winter activities such as skiing, snowmobiling, dog sledding, snowshoeing, ice fishing, and sledding for visitors to experience. Additionally, there are plenty of opportunities to explore the stunning landscapes. There are a variety of cabins, lodges, and traditional snow igloos to stay in, making it possible to truly immerse oneself in Finnish culture. The Northern Lights can also be seen in this area, making it a truly magical place to visit.')
      setShowItinerary(true)
      setSelectedFilters([])
      setButtonEnabled(false)
    }, 1000)
  }

  const getTrip = async (prompt) => {
    if (!buttonEnabled) { return }

    startLoading()

    const text = buildPrompt(prompt, destination)
    const result = await buildItinerary(text)

    console.log(result)

    setItinerary(result)
    setShowItinerary(true)
    reset()
  }

  const onRandom = async () => {
    setShowItinerary(false)

    const val = (Math.floor(Math.random() * 100)) % 2
    let name = ''

    if (val === 0) {
      const countriesArr = Object.values(countries)
      const selected = countriesArr[Math.floor(Math.random() * countriesArr.length)]
      name = selected.name

      setDestination(selected.name)
    } else if (val === 1) {
      const continentsArr = Object.values(continents)
      const selected = continentsArr[Math.floor(Math.random() * continentsArr.length)]
      name = selected

      setDestination(selected)
    }

    setShowLoading(true)

    const text = buildPrompt(Prompts.Itinerary, name)
    const result = await buildItinerary(text)

    setItinerary(result)
    setShowItinerary(true)
    reset()
  }

  return (
    <div className='app-container'>
      <Header />
      { !showLoading ? 
        <>
          <DestinationSelector onStartType={onStartType} onDestinationSelect={getDestination} />
          <FilterList updateSelectedFilters={setSelectedFilters} />
          <ItineraryButton isEnabled={buttonEnabled} onTap={ () => getTrip(Prompts.Itinerary) }/>
          <SuggestionButton isEnabled={buttonEnabled} onTap={ () => getTrip(Prompts.Suggestion) } />
          { !buttonEnabled ? <RandomButton onTap={onRandom} /> : <></> }
        </> : <></> 
      }
      { showItinerary ? <Result destination={destination} result={itinerary} /> : <></> }
      { showLoading ? <Loading /> : <></> }
    </div>
  );
}

export default App;
