import Filter from "./Filter"
import { useState } from "react"
import Filters from "../data/Filters"

const FilterList = ({ updateSelectedFilters }) => {
  const [filters, setFilters] = useState(Filters)
  
  const onFilterClicked = (updatedFilter) => {

    const updatedFilters = filters.map((obj) => { 
      if (obj.id === updatedFilter.id) {
        return updatedFilter 
      } else {
        if (obj.category === updatedFilter.category && obj.isSelected) {
          return { ...obj, isSelected: !obj.isSelected }
        } else {
          return obj
        }
      }
    })
    
    setFilters(updatedFilters)
    updateSelectedFilters(updatedFilters.filter((f) => (f.isSelected)))
  }

  return (
    <div className='filters-list-container'>
      <label>👇 Add some filters below 👇</label>
      <div className='filters-list'>
        { filters.map((filter) => ( <Filter key={filter.id} onFilterClicked={onFilterClicked} data={filter} /> )) }
      </div>
    </div>
  )
}

export default FilterList