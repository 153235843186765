// Solution: https://edvins.io/how-to-strip-emojis-from-string-in-java-script
const stripEmoji = (text) => {
  return text.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    ''
  )
  .replace(/\s\s+/g, ' ')
  .trim();
}

export default stripEmoji