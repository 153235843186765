const SuggestionButton = ({isEnabled, onTap}) => {
  return (
    <button 
    style={{ 
      borderColor: isEnabled ? '#2ecc71' : '#bdc3c7',
      color: isEnabled ? '#2ecc71' : '#bdc3c7',
      cursor: isEnabled ? 'pointer' : 'cursor',
    }} 
    className='secondary-app-button' 
    onClick={onTap}
    >Give me suggestions</button>
  )
}

export default SuggestionButton