const Filters = [
  {
    id: 1,
    option: "🔥 Hot",
    isSelected: false,
    color: "#f22613",
    category: 1,
  },
  {
    id: 2,
    option: "❄️ Cold",
    isSelected: false,
    color: "#f22613",
    category: 1,
  },
  {
    id: 3,
    option: "🎒 1 week",
    isSelected: false,
    color: "#87d37c",
    category: 2,
  },
  {
    id: 4,
    option: "👝 2 weeks",
    isSelected: false,
    color: "#87d37c",
    category: 2,
  },
  {
    id: 11,
    option: "🧳 1 month",
    isSelected: false,
    color: "#87d37c",
    category: 2,
  },
  {
    id: 5,
    option: "🏖️ Summer",
    isSelected: false,
    color: "#8c14fc",
    category: 3,
  },
  {
    id: 6,
    option: "🍁 Fall",
    isSelected: false,
    color: "#8c14fc",
    category: 3,
  },
  {
    id: 7,
    option: "⛄️ Winter",
    isSelected: false,
    color: "#8c14fc",
    category: 3,
  },
  {
    id: 8,
    option: "💐 Spring",
    isSelected: false,
    color: "#8c14fc",
    category: 3,
  },
  {
    id: 9,
    option: "👮 Safe",
    isSelected: false,
    color: "#0b7fab",
    category: 4,
  },
  {
    id: 10,
    option: "💃 Fun",
    isSelected: false,
    color: "#f9690e",
    category: 5,
  },
  {
    id: 12,
    option: "🤑 Cheap",
    isSelected: false,
    color: "#1abc9c",
    category: 5,
  }
];

export default Filters;
