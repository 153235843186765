import Lottie from 'lottie-react'
import planeAnimation from '../lotties/plane-flying.json'
import React, { useState, useEffect } from 'react';

const labels = [
  'Boarding plane 🚶', 
  'Prepare for takeoff 💺', 
  'Takeoff 🛫', 
  'Flying 🛩️', 
  'Prepare for landing 💺', 
  'Landed 🛬'
]

const Loading = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const style = {
    height: 100,
    width: 100
  };

  useEffect(() => {
    if (currentIndex < labels.length - 1) {
      const timer = setTimeout(() => {
        setCurrentIndex((prevIndex) => prevIndex + 1)
      }, 2000)
      
      return () => {
        clearTimeout(timer)
      };
    }
  }, [currentIndex])

  return (
    <div className='loader-container'>
      <Lottie
        className="loader"
        animationData={planeAnimation}
        style={style}
        autoplay={true}
        loop={true}
      />
      <label className='loader-label'>{labels[currentIndex]}</label>
    </div>
  );
}

export default Loading